<template>
    <modal ref="enviarCotizacion" titulo="Enviar cotización" adicional="Enviar" @adicional="enviarCotizacion">
        <div class="m-3">
            <ValidationObserver ref="validator">
                <div class="row justify-content-center">
                    <div class="col-6 mb-3">
                        <ValidationProvider v-slot="{ errors }" name="día vigencia" rules="required">
                            <p class="input-label-top">Días de vigencia de precio</p>
                            <el-input-number :controls="false" v-model="dias_vigencia_precio" class="w-100" size="small" placeholder="Días" />
                            <vee-error :text="errors[0]"></vee-error>
                        </ValidationProvider>
                    </div>
                    <div class="col-6 mb-3">
                        <ValidationProvider v-slot="{ errors }" name="días crédito" rules="required">
                            <p class="input-label-top">Días de crédito</p>
                            <el-input-number :controls="false" v-model="dias_credito" class="w-100" size="small" placeholder="Días" />
                            <vee-error :text="errors[0]"></vee-error>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 mb-3">
                        <ValidationProvider v-slot="{ errors }" name="comentario" rules="required">
                            <p class="input-label-top">Comentario</p>
                            <el-input v-model="comentario" type="textarea" class="w-100" size="small" placeholder="Comentarios" :autosize="{ minRows: 4}" />
                            <vee-error :text="errors[0]"></vee-error>
                        </ValidationProvider>
                    </div>
                </div>
           </ValidationObserver>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            dias_vigencia_precio: '',
            dias_credito: '',
            comentario: '',
        }
    },
    methods: {
        toggle(){
            this.$refs.validator.reset()
            this.$refs.enviarCotizacion.toggle()
        },
        async enviarCotizacion(){
            const validado = await this.$refs.validator.validate()
            if (!validado) return
            const payload = {
                dias_vigencia_precio: this.dias_vigencia_precio,
                dias_credito: this.dias_credito,
                comentario: this.comentario
            }
            this.$emit('enviarCotizacion', payload)
            this.$refs.enviarCotizacion.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
